/*
  Created BY: Prasannadatta kawadkar
  Created On: 16 Dec 2022
  Creation: common style file for all pages
*/

.main-heading {
  padding: 4px 12px;
  font-size: 30px;
  font-weight: bold;
  cursor: default;
  color: #413f42;
}

.body-wrapper {
  //margin: 1% 2% 2% 15%;
  padding: 10px 0px 0px 10px;
  margin-left: 200px;
  height: 100vh;
}

.body-wrapper-scroll-y {
  margin: 1% 2% 2% 15%;
  padding: 0px 10px 80px 0px;
  height: 100vh;
  overflow-y: scroll;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #f3f5f7;
  z-index: 1;
}

.graph-title1 {
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
}

/* hide scrollbar but allow scrolling */
.body-scrollable {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.body-scrollable::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* Firefox */
.scrollable {
  scrollbar-width: 4px;
  scrollbar-color: grey #FFFFFF;
}

/* Chrome, Edge and Safari */
.scrollable::-webkit-scrollbar {
  width: 4px;
  width: 4px;
}
.scrollable::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f3f5f7;
}

.scrollable::-webkit-scrollbar-track:hover {
  background-color: #f3f5f7;
}

.scrollable::-webkit-scrollbar-track:active {
  background-color: #f3f5f7;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #413f42;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #413f42;
}

.scrollable::-webkit-scrollbar-thumb:active {
  background-color: #413f42;
}

/* Firefox */
.scrollable1 {
  scrollbar-width: thin;
  scrollbar-color: grey #FFFFFF;
}
/* rgb(255, 89, 123) changes scrollbar color to grey */
/* Chrome, Edge and Safari */
.scrollable1::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
.scrollable1::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #FFF;
}

.scrollable1::-webkit-scrollbar-track:hover {
  background-color: #FFF;
}

.scrollable1::-webkit-scrollbar-track:active {
  background-color: #FFF;
}

.scrollable1::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #576f72;
}

.scrollable1::-webkit-scrollbar-thumb:hover {
  background-color: #576f72;
}

.scrollable1::-webkit-scrollbar-thumb:active {
  background-color: #576f72;
}

.btn1 {
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  background: #EB455F;
  padding: 6px 12px 12px 12px;
  text-decoration: none;
  margin: 12px 4px 12px 0px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.btn1:hover {
  background: #ff597b;
  text-decoration: none;
}
.btn-track {
  position: relative;
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  background: #EB455F;
  padding: 6px 24px 12px 24px;
  text-decoration: none;
  margin: 45px 4px 12px 12px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.btn-track:hover {
  background: #ff597b;
  text-decoration: none;
}
.btn2 {
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  background: #576F72;
  padding: 6px 12px 12px 12px;
  text-decoration: none;
  margin: 12px 0px 12px 4px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.btn2:hover {
  background: #3D5656;
  text-decoration: none;
}

.btn3 {
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  color: #252525;
  font-size: 14px;
  background: #FFF;
  padding: 6px 12px 12px 12px;
  text-decoration: none;
  //margin: 12px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.btn3:hover {
  background: #3D5656;
  text-decoration: none;
}

.btn4 {
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  fill: #FFF;
  font-size: 14px;
  background: #0C7B93;
  padding: 4px 10px 8px 10px;
  text-decoration: none;
  margin: 2px;
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.btn4:hover {
  background: #2B3467 ;
  text-decoration: none;
}

.white-box {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  //padding: 12px;
  margin: 12px !important;
  border-radius: 4px;
}

table {
  width: 100%;
}

.blur-effect {
  filter: blur(5px);
  opacity: 0.7;
}

.blur-message-box {
  height: 12rem;
  width: 40rem;
  background: #FFF;
  border-radius: 4px;
  position: absolute;
  z-index: 3;
  left: 40%;
  top: 28%;
  box-shadow: 0 0.5em 3em rgba(0,0,0,0.4);
}

.blur-message {
  position: relative;
  left: 34%;
  top: 40%;
  z-index: 4;
  font-size: 23px;
  font-weight: bold;
  color: #252525;
}

.search-bar {
  width: 28rem;
  border: 1px solid rgba(226,226,226,0.431);
  box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
  border-radius: 4px;
}

.input-bar-button1 {
  position: relative;
  top: 9px;
  right: 0px;
}

.input-bar-button2 {
  position: relative;
  top: 6px;
  left: 4px;
}

/* Modal Overlay */
.new-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal */
.new-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

/* Modal Content */
.new-modal-content {
  position: relative;
}

/* Close Button */
.new-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.search-dropdown {
    position: relative;
    background: #fff
    //width: 200px;
}

.search-dropdown input {
    width: 100%;
    padding: 8px;
}

.search-dropdown-list {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    //border: 1px solid #ccc;
    background-color: white;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.search-dropdown-list.show {
    max-height: 200px;
    overflow: auto;
    box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
}

.search-dropdown-list li {
    padding: 5px;
    cursor: pointer;
    display: block;
    border-bottom: 1px solid #e2e2e2;
    margin: 0;
}

.search-dropdown-list li:hover {
    background-color: #f0f0f0;
}
/* Input container */
.search-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

/* Clear icon */
.search-clear-icon {
    position: absolute;
    right: 7px;
    cursor: pointer;
    padding: 0;
    font-size: 1.4rem;
    color: #808080;
    width: 17px;
    background: aliceblue;
    top: 9px;
    padding-left: 2px;
    height: 33px;
    border-top-right-radius: 4px;
    padding-top: 3px;
    border-bottom-right-radius: 4px;
}

.search-clear-icon:hover {
    color: black; /* Change the color when hovering */
}
.input-date {
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  box-shadow: none;
  box-sizing: inherit;
  height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;
  margin-bottom: 10px !important;
}

.fileViewer {
  .modal {
    display: inline; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    padding: 10px 0; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto !important;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    height: 90vh;
  }
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .modal-close {
      cursor : pointer;
    color: #373737;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover {
      color: #000
    }
  }

  .modal-header {
    padding: 2px 16px;
    background-color: #e2e9ed;
    color: #8d8d8d;
    height: 50px;
  }

  .modal-header-title {
    padding-top: 8px;
    color: #373737;
    font-size: 1.16em;
  }

  .modal-body {
    display: flex;
    padding: 10px 16px;
    height: 74vh !important;
    overflow-y: auto;
  }

  .modal-body .container div div {
    height: 100% !important;
    // min-height: 200px !important
  }

  .modal-footer {
    padding: 11px 16px;
    background-color: #e2e9ed;
    height: 50px;
  }

  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
}
